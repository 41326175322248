import './JzInputHeader.css';
import { JzInput } from "../input/JzInput"

export const JzInputHeader = ({name, type = 'text', value = '', onChange}: any) => {
    return (
        <div className='Jz-input-container'>
            <label className='Jz-input-label'>{name}</label>
            <JzInput
            type={type}
            value={value}
            onChange={onChange}></JzInput>
        </div>
    )
}