import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Login } from './components/view/login/Login';
import { Header } from './components/view/header/Header';
import { Cart } from './components/view/cart/Cart';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Login></Login>
  },
  {
    path: '/header',
    element: <><Header></Header><Cart></Cart></>
  }
])

const App = () => <RouterProvider router={router}></RouterProvider>

export default App;
