const LoginService = {
    validateLogin: (user:string, password:string) => !!(user && password),
    postLogin: (username:string, password:string) => {
        return fetch(process.env.REACT_APP_API_BASE!, {
            method: 'POST',
            body: JSON.stringify({username, password})
        }).then((data) => data.json())
    }
}

export default LoginService;