import './JzInput.css';

export const JzInput = ({type = 'text', value = '', onChange, ...props}: any) => {
    return (
        <input
        className='Jz-input'
        type={type}
        value={value}
        onChange={onChange}
        {...props}></input>
    )
}