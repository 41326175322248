import { useEffect, useState } from "react";
import { JzButton } from "../../core/button/JzButton";
import { JzInput } from "../../core/input/JzInput";

interface Expense {
    name: string;
    cost: string;
}

export const Cart = () => {
    const [expenses, setExpenses] = useState<Expense[]>([]);
    const [total, setTotal] = useState(0);

    const addExpense = () => setExpenses([...expenses, {name: '', cost: '0'}])
    const removeExpense = (index: number) => setExpenses(expenses.filter((x, i) => index !== i));
    const updateName = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setExpenses(expenses.map((x, i) => index === i ? {name: event.target.value, cost: x.cost} : x))
    }
    const updateCost = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        setExpenses(expenses.map((x, i) => index === i ? {cost: event.target.value, name: x.name} : x))
    }

    useEffect(() => {
        let sum = expenses.reduce((acumulator, currentValue) => acumulator + (isNaN(parseFloat(currentValue.cost)) ? 0 : parseFloat(currentValue.cost)), 0);
        setTotal(sum);
    }, [expenses])

    return (
        <>
        <JzButton text={'Agregar'} onClick={addExpense}></JzButton>
        <table style={{width: '100%'}}>
            {expenses.map((x, i) => (
                <tr style={{width: '100%'}}>
                    <td style={{width: '50%'}}><JzInput value={x.name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateName(event, i)} style={{width: '90%', minWidth: '0'}}></JzInput></td>
                    <td style={{width: '25%'}}><JzInput value={x.cost} onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateCost(event, i)} style={{width: '90%', minWidth: '0'}} type={'number'}></JzInput></td>
                    <td style={{width: '25%'}}><JzButton text={'quitar'} small={true} onClick={() => removeExpense(i)}></JzButton></td>
                </tr>
            ))}
        </table>
        <span>{total}</span>
        </>
    )
}